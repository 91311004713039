import { gql } from "@apollo/client";

export interface IVendorPlanByUser {
  vendorPlanByUser: IVendorPlanByUserVariables;
}

export interface IVendorPlanByUserVariables {
  plan: string;
  maxUsers: number;
  name: string;
  isTrial: boolean;
  active: boolean;
  isStarted: boolean;
  usersAvailable?: number;
  isFullAccessEnabled: boolean;
}

const VENDOR_PLAN_BY_USER = gql`
  query VendorPlanByUser {
    vendorPlanByUser {
      plan
      name
      isTrial
      active
      isStarted
      usersAvailable
      isFullAccessEnabled
    }
  }
`;

export default { VENDOR_PLAN_BY_USER };
