import { gql } from "@apollo/client";
import { IMultimedia, IMultimediaMessage } from "types/Multimedia.type";
import { IUser } from "types/User.types";

export interface IMultimediasResponse {
  multimedias: IMultimedia[];
}

export interface IMultimediasPaginatedResponse {
  multimediasPaginated: IMultimediaPaginated;
}

export interface IMultimediaPaginated {
  multimediasPaginated: IMultimedia[];
  total: number;
}

export interface IMultimediasInput {
  tagsNames?: string[];
  name?: string;
  tagsIds?: string[];
  user?: string;
}

export interface IMultimediasPaginatedInput {
  tagsNames?: string[];
  name?: string;
  tagsIds?: string[];
  user?: string;
  limit?: number;
  offset?: number;
}

export interface IGetMultimediaInput {
  id: string;
}

const GET_MULTIMEDIA = gql`
  query multimedia($getMultimediaInput: GetMultimediaInput!) {
    multimedia(getMultimediaInput: $getMultimediaInput) {
      id
      name
      description
      tags
      score
      fileUrl
      thumbnailUrl
      shortDescription
      score
      multimediaType
      mimeType
    }
  }
`;

const GET_MULTIMEDIAS = gql`
  query multimedias($getMultimediasInput: GetMultimediasInput!) {
    multimedias(getMultimediasInput: $getMultimediasInput) {
      id
      isQuickie
      name
      description
      shortDescription
      tags
      score
      fileUrl
      thumbnailUrl
      score
      version
      multimediaType
      mimeType
      uploadedBy {
        id
        name
        lastname
        email
        profileImage
      }
    }
  }
`;

const GET_MULTIMEDIAS_PAGINATED = gql`
  query MultimediasPaginated(
    $multimediasPaginatedInput: MultimediasPaginatedInput!
  ) {
    multimediasPaginated(
      multimediasPaginatedInput: $multimediasPaginatedInput
    ) {
      total
      multimediasPaginated {
        id
        isQuickie
        name
        description
        shortDescription
        tags
        score
        fileUrl
        thumbnailUrl
        score
        version
        multimediaType
        mimeType
        uploadedBy {
          id
          name
          lastname
          email
          profileImage
        }
      }
    }
  }
`;

export interface IGetAllMultimediaMessagesInput {
  multimediaId: string;
}

export interface IGetAllMultimediaMessagesResponse {
  getAllMultimediaMessage: IMultimediaMessage[];
}

const GET_ALL_MULTIMEDIA_MESSAGES = gql`
  query getAllMultimediaMessage(
    $getAllMultimediaMessagesInput: GetAllMultimediaMessagesInput!
  ) {
    getAllMultimediaMessage(
      getAllMultimediaMessagesInput: $getAllMultimediaMessagesInput
    ) {
      id
      message
      multimediaId
      createdAt
      user {
        profileImage
        name
        lastname
        createdAt
        id
      }
    }
  }
`;

const USER_MULTIMEDIAS = gql`
  query GetUserMultimedias($getUserMultimediasInput: GetUserMultimediasInput!) {
    getUserMultimedias(getUserMultimediasInput: $getUserMultimediasInput) {
      id
      isQuickie
      name
      description
      tags
      score
      fileUrl
      thumbnailUrl
      shortDescription
      score
      version
      multimediaType
      mimeType
      uploadedBy {
        id
        name
        lastname
        email
        profileImage
      }
      isOpened
    }
  }
`;

export interface IUserMultimediasPaginatedInput {
  limit: number;
  skip: number;
  tags?: string[];
  search?: string;
}

export interface IUserMultimediasPaginatedResponse {
  getUserMultimediasPaginated: IUserMultimediaDetail;
}

export interface IUserMultimediaDetail {
  multimediasPaginated: IMultimedia[];
  total: number;
  totalPages: number;
}

const USER_MULTIMEDIAS_PAGINATED = gql`
  query GetUserMultimediasPaginated(
    $getUserMultimediasInput: GetUserMultimediasPaginatedInput!
  ) {
    getUserMultimediasPaginated(
      getUserMultimediasInput: $getUserMultimediasInput
    ) {
      multimediasPaginated {
        id
        isQuickie
        name
        description
        tags
        score
        fileUrl
        thumbnailUrl
        shortDescription
        score
        version
        multimediaType
        mimeType
        uploadedBy {
          id
          name
          lastname
          email
          profileImage
        }
      }
      total
      totalPages
    }
  }
`;

const GET_SIGNED_URL_FOR_MULTIMEDIA = gql`
  query Query($key: String!) {
    getSignedUrlMediaStorage(key: $key)
  }
`;

const GET_SIGNED_URL_FOR_MULTIMEDIA_GET = gql`
  query Query($key: String!) {
    getSignedUrlMediaStorageGet(key: $key)
  }
`;

export interface IGetUserMultimediaReport {
  getUserMultimediaStats: IGetUserMultimediaReportInput;
}

export interface IGetUserMultimediaReportInput {
  quantityOpened: Number;
  quantityQualified: Number;
  users: IUserMultimediaReport[];
}

export interface IUserMultimediaReport {
  name: string;
  lastname: string;
  email: string;
  profileImage: string;
  isOpened: boolean;
  rate: number;
  id: string;
}

const GET_MULTIMEDIA_STATS = gql`
  query GetUserMultimediaStats($multimediaId: String!) {
    getUserMultimediaStats(multimediaId: $multimediaId) {
      quantityOpened
      quantityQualified
      users {
        name
        lastname
        email
        profileImage
        isOpened
        rate
        id
      }
    }
  }
`;

export interface IGetUsersMultimediaSharedPaginatedInput {
  skip?: number;
  limit?: number;
  search?: string | null;
  multimediaId: string;
}

export interface IGetUsersMultimediaSharedPaginatedResponse {
  getSharedUsersPaginated?: IGetUsersMultimediaSharedPaginated;
}

export interface IGetUsersMultimediaSharedPaginated {
  usersPaginated?: IGetUserMultimedia[];
  total: number;
  totalPages: number;
}

export interface IGetUserMultimedia {
  user: IUser;
  deleted: boolean;
}

const GET_MULTIMEDIA_USERS_SHARED_PAGINATED = gql`
  query GetSharedUsersPaginated(
    $getUsersMultimediaSharedPaginatedInput: GetUsersMultimediaSharedPaginatedInput!
  ) {
    getSharedUsersPaginated(
      getUsersMultimediaSharedPaginatedInput: $getUsersMultimediaSharedPaginatedInput
    ) {
      usersPaginated {
        user {
          id
          name
          lastname
          profileImage
          email
        }
        deleted
      }
      totalPages
      total
    }
  }
`;

export default {
  GET_MULTIMEDIAS,
  GET_MULTIMEDIA,
  GET_ALL_MULTIMEDIA_MESSAGES,
  USER_MULTIMEDIAS,
  USER_MULTIMEDIAS_PAGINATED,
  GET_SIGNED_URL_FOR_MULTIMEDIA,
  GET_SIGNED_URL_FOR_MULTIMEDIA_GET,
  GET_MULTIMEDIA_STATS,
  GET_MULTIMEDIA_USERS_SHARED_PAGINATED,
  GET_MULTIMEDIAS_PAGINATED,
};
