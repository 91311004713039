export { default as gqlActionPlan } from "./ActionPlan";
export { default as gqlActivities } from "./Activities";
// * refresh queries without mutations
export { default as gqlAnonymousComment } from "./AnonymousComments";
export { default as gqlAppreciation } from "./Appreciation";
export { default as gqlArea } from "./Area";
export { default as gqlCareer } from "./Career";
export { default as gqlCertificateUser } from "./Certificate-user";
export { default as gqlCertificate } from "./Certificates";
export { default as gqlChallenge } from "./Challenge";
export { default as gqlCoins } from "./Coins";
export { default as gqlCommon } from "./Common";
export { default as gqlCorporateValues } from "./CorporateValues";
export { default as gqlDashboard } from "./Dashboard";
export { default as gqlDimension } from "./Dimension";
export { default as gqlDivision } from "./Division";
export { default as gqlDoNothing } from "./DoNothing";
export { default as gqlEndorsement } from "./Endorsement";
export { default as gqlExample } from "./Example";
export { default as gqlFeedback } from "./Feedback";
export { default as gqlForm } from "./Form";
export { default as gqlGoal } from "./Goal";
export { default as gqlJobyfine } from "./Jobyfine";
export { default as gqlMaturity } from "./Maturity";
export { default as gqlMessage } from "./Message";
export { default as gqlMultimedia } from "./Multimedia";
export { default as gqlNotification } from "./Notification";
export { default as gqlPlan } from "./Plan";
export { default as gqlPublicAppreciation } from "./PublicAppreciation";
export { default as gqlQuestion } from "./Question";
export { default as gqlReaction } from "./Reaction";
export { default as gqlRecognition } from "./Recognitions";
export { default as gqlRole } from "./Role";
export { default as gqlSkill } from "./Skills";
export { default as gqlTag } from "./Tag";
export { default as gqlTask } from "./Task";
export { default as gqlTeam } from "./Team";
export { default as gqlUser } from "./User";
export { default as gqlUserActivity } from "./UserActivity";
export { default as gqlUserFeedback } from "./UserFeedback";
export { default as gqlUserRequired } from "./UserRequired";
export { default as gqlVacation } from "./Vacation";
export { default as gqlVendor } from "./Vendor";
export { default as gqlVendorPlan } from "./VendorPlan";
