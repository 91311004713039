import { useTemplateContext } from "context/template/TemplateContext";
import { TemplateType } from "stores/template/useTemplateStore";

export default function HeaderBgBlue() {
  const { template } = useTemplateContext();

  if (template === TemplateType.Classic) {
    return null;
  }
  return (
    <div
      id="header-bg-blue"
      style={{
        position: "absolute",
        width: "1980px",
        height: "1640px",
        backgroundColor: "#0599FF",
        borderRadius: "50%",
        filter: "blur(64px)",
        opacity: 0.2,
        top: "-600px",
        left: "50%",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        zIndex: 0,
      }}
    ></div>
  );
}
