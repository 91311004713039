import { createContext, ReactElement } from "react";

export interface IOpenDrawer {
  drawerComponent: JSX.Element;
  onOpenDrawer?: () => void;
  onCloseDrawer?: () => void;
  className?: string;
  firstWidth?: number;
}

interface IDrawerMethods {
  openDrawer: (params: IOpenDrawer) => void;
  setDrawerComponent: (newDrawerComponent: JSX.Element) => void;
  closeDrawer: () => void;
  isOpen: boolean;
  isOpen2: boolean;
  isOpen3: boolean;
  isOpen4: boolean;
  drawer: ReactElement;
  drawer2: ReactElement;
  drawer3: ReactElement;
  drawer4: ReactElement;
  firstWidth?: number;
}

const drawerMethods = {
  openDrawer: () => null,
  setDrawerComponent: () => null,
  closeDrawer: () => null,
  isOpen: false,
  isOpen2: false,
  isOpen3: false,
  isOpen4: false,
  drawer: <></>,
  drawer2: <></>,
  drawer3: <></>,
  drawer4: <></>,
  firstWidth: 75,
};

export const DrawerContext = createContext<IDrawerMethods>(drawerMethods);
