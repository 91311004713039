import { gql } from "@apollo/client";

export const CREATE_EXPORT_FILE = gql`
  mutation CreateExport($exportType: String!) {
    createExport(exportType: $exportType) {
      id
    }
  }
`;

export default {
  CREATE_EXPORT_FILE,
};
