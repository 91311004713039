import { useTemplateContext } from "context/template/TemplateContext";
import { useIsScreenMd } from "hooks/useIsScreenMd";
import dynamic from "next/dynamic";
import { FC, ReactNode } from "react";
import { TemplateType } from "stores/template/useTemplateStore";
import HeaderBgBlue from "../Sidebar/HeaderBgBlue";
import Sidebar from "../Sidebar/Sidebar";
import SidebarV2 from "../Sidebar/SidebarV2";

const NavBar = dynamic(() => import("components/NavBar/NavBar"), {
  ssr: false,
});

interface ManagerProLayoutProps {
  children?: ReactNode;
}

const ManagerProLayout: FC<ManagerProLayoutProps> = ({ children }) => {
  const { template } = useTemplateContext();
  const isScreenMd = useIsScreenMd();

  return (
    <div
      className="max-w-screen flex h-full w-screen overflow-x-hidden bg-white dark:bg-black"
      id="site-container"
    >
      <div className="w-auto" id="sidebar-v1">
        <Sidebar render={template === TemplateType.Classic || !isScreenMd} />
      </div>

      <div className="overflow-y-none relative flex h-full w-full flex-col justify-start lg:items-center">
        <NavBar />
        <HeaderBgBlue />
        <div
          className="dashboard-container relative mx-auto flex w-full max-w-max-size-grid flex-row gap-1 overflow-y-auto overflow-x-hidden"
          id="manager-container"
        >
          <SidebarV2 render={template === TemplateType.Aurora && isScreenMd} />
          {children}
        </div>
      </div>
      <style jsx global>{`
        .dashboard-container {
          height: calc(100dvh - var(--sidebar-header-mobile));
          width: 100%;
        }
        @media (min-width: 769px) {
          .dashboard-container {
            height: calc(100dvh - var(--sidebar-header-desktop));
          }
        }
      `}</style>
    </div>
  );
};

export default ManagerProLayout;
