import { ESystemRoles } from "@constants/SystemRoles";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { AppProps } from "next/dist/shared/lib/router/router";
import { Montserrat } from "next/font/google";
import { useRouter } from "next/router";
import { guardCheckUserRole } from "utils/guards";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./routes";
import { IRoute, RouteLayout } from "./types";

export const montserrat = Montserrat({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["system-ui", "sans-serif", "arial"],
});

type IRoutes = {
  PRIVATE: {
    [route in keyof typeof PRIVATE_ROUTES]: IRoute;
  };
  PUBLIC: {
    [route in keyof typeof PUBLIC_ROUTES]: IRoute;
  };
};

export const ROUTES: IRoutes = {
  PRIVATE: PRIVATE_ROUTES,
  PUBLIC: PUBLIC_ROUTES,
};

const Routes = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));

  const getCurrentRoute = (
    routes: IRoutes["PRIVATE"] | IRoutes["PUBLIC"],
    isPrivate = false
  ) => {
    let currentRoute: IRoute;
    let isPrivatePath = false;

    Object.values(routes).some((route) => {
      const isRoute = route.path.includes(router.pathname);

      if (isRoute) {
        isPrivatePath = isPrivate;
        currentRoute = route;
      }
      return isRoute;
    });

    return { currentRoute, isPrivatePath };
  };

  let route: IRoute;
  // * Search for the current route in private routes
  const { currentRoute, isPrivatePath } = getCurrentRoute(ROUTES.PRIVATE, true);
  route = currentRoute;

  // * If route was not private, search for the current route in public routes
  if (!isPrivatePath) {
    route = getCurrentRoute(ROUTES.PUBLIC).currentRoute;
  }

  // * jobyfine is and addon so we need to check if the route has it separately
  if ((route?.jobyfineRecruiter || route?.jobyfineRequester) && !user?.isi) {
    if (route?.jobyfineRecruiter && route?.jobyfineRequester) {
      // * if the route is both recruiter and requester, we need to check if the have at least one of them
      if (
        !user?.jobyfineRecruiter &&
        !user?.jobyfineRequester &&
        user?.systemRole !== ESystemRoles.ADMIN
      ) {
        router.push("/404");
        return null;
      }
    } else if (
      route.jobyfineRecruiter &&
      !user?.jobyfineRecruiter &&
      user?.systemRole !== ESystemRoles.ADMIN
    ) {
      router.push("/404");
      return null;
    } else if (
      route.jobyfineRequester &&
      !user?.jobyfineRequester &&
      user?.systemRole !== ESystemRoles.ADMIN
    ) {
      router.push("/404");
      return null;
    }
  } else if (
    user &&
    !guardCheckUserRole(
      currentRoute?.roleGuards || [],
      user?.systemRole,
      user?.isi
    )
  ) {
    router.push("/404");
    return null;
  }

  return (
    <>
      {isPrivatePath ? (
        <PrivateRoute>
          <RouteLayout layout={route?.layout}>
            <div
              className={`${montserrat.className} relative grid w-full overflow-y-auto`}
              id="privateRoute"
            >
              <Component {...pageProps} />
            </div>
          </RouteLayout>
        </PrivateRoute>
      ) : (
        <RouteLayout layout={route?.layout}>
          <main
            className={`${montserrat.className} relative grid overflow-y-auto`}
            id="public-main"
          >
            <Component {...pageProps} />
          </main>
        </RouteLayout>
      )}
    </>
  );
};

export default Routes;
