import { DocumentNode, gql } from "@apollo/client";
import {
  EContractType,
  EOfferState,
  EOfferType,
  EProcessOfferState,
  ERequestType,
  IApplicant,
  IFileExtras,
  IProcess,
  IProcessOffer,
  IRequest,
  OfferDefinitionType,
} from "types/Jobyfine.type";
import { ECurrency } from "types/Task.type";
import { IUser } from "types/User.types";
import { EEmployeeContractType } from "types/Vacation.type";

const CREATE_APPLICANT = gql`
  mutation CreateApplicant($createApplicantInput: CreateApplicantInput!) {
    createApplicant(createApplicantInput: $createApplicantInput) {
      id
    }
  }
`;

const UPDATE_APPLICANT = gql`
  mutation UpdateApplicant($updateApplicantInput: UpdateApplicantInput!) {
    updateApplicant(updateApplicantInput: $updateApplicantInput) {
      id
    }
  }
`;

const UPDATE_APPLICANT_FROM_PORTAL = gql`
  mutation UpdateApplicantFromPortal(
    $updateApplicantInput: UpdateApplicantInput!
  ) {
    updateApplicantFromPortal(updateApplicantInput: $updateApplicantInput) {
      id
    }
  }
`;

const DELETE_APPLICANT = gql`
  mutation DeleteApplicant($deleteApplicantId: String!) {
    deleteApplicant(id: $deleteApplicantId) {
      id
    }
  }
`;

const GENERATE_AI_CV = gql`
  mutation GenerateAICV(
    $generateAicvId: String!
    $collaborator: Boolean!
    $language: String!
    $forcenew: Boolean!
  ) {
    generateAICV(
      id: $generateAicvId
      collaborator: $collaborator
      language: $language
      forcenew: $forcenew
    ) {
      ofuscatedName
      education {
        dateEnd
        dateStart
        description
        title
      }
      extraEducation {
        dateEnd
        dateStart
        description
        title
      }
      languages {
        level
        name
      }
      tagLine
      techSkills
      workExperience {
        date
        description
        title
        company
      }
      extraWorkExperience {
        date
        description
        title
        company
      }
      certifications {
        name
        date
      }
    }
  }
`;

export interface ICreateOfferInput {
  createOfferInput: {
    name: string;
    description: string;
    area?: string;
    client?: string;
    vancancy: number;
    typeOffer?: EOfferType;
    image?: string;
    maxSalary?: number;
    currency?: string;
    requester?: string;
    state?: EOfferState;
    definition?: OfferDefinitionType;
    fake?: boolean;
    requests?: string[];
    tags?: string[];
    profiles?: string[];
    recruiter?: string;
    country?: string;
    contractType?: EContractType;
  };
}

export interface ICreateOfferInputRes {
  createOffer: {
    id: string;
    name: string;
    description: string;
    vancancy: number;
    createdAt: string;
    publicEnd?: string;
    image?: string;
    state: EOfferState;
    typeOffer: OfferDefinitionType;
    currency?: string;
    maxSalary?: number;
    requester?: IUser;
    definition?: OfferDefinitionType;
    fake?: boolean;
    tags?: string[];
    recruiter?: string;
    country?: string;
    contractType?: EContractType;
  };
}

const CREATE_OFFER = gql`
  mutation createOffer($createOfferInput: CreateOfferInput!) {
    createOffer(createOfferInput: $createOfferInput) {
      id
      name
      description
      vancancy
      fake
      area {
        id
        name
      }
      createdAt
      country
      publicEnd
      image
      state
      typeOffer
      maxSalary
      currency
      definition
      tags
      recruiter
      requester {
        id
        name
      }
    }
  }
`;

export interface IUpdateOfferInput {
  updateOfferInput: {
    id: string;
    name: string;
    description: string;
    area?: string;
    client?: string;
    vancancy: number;
    image?: string;
    state?: EOfferState;
    typeOffer?: EOfferType;
    maxSalary?: number;
    currency?: string;
    requester?: string;
    definition?: OfferDefinitionType;
    fake?: boolean;
    requests?: string[];
    tags?: string[];
    profiles?: string[];
    recruiter?: string;
    country?: string;
    contractType?: EContractType;
  };
}

export interface IUpdateOfferInputRes extends IUpdateOfferInput {
  updateOffer: {
    id: string;
  };
}

const UPDATE_OFFER = gql`
  mutation updateOffer($updateOfferInput: UpdateOfferInput!) {
    updateOffer(updateOfferInput: $updateOfferInput) {
      id
      name
      description
      vancancy
      country
      area {
        id
        name
      }
      client {
        id
        name
      }
      createdAt
      publicEnd
      image
      state
      typeOffer
      maxSalary
      currency
      definition
      tags
      profiles
      recruiter
      requester {
        id
        name
      }
    }
  }
`;

export interface IUpdateStateOfferInputRes {
  updateStateOffer: {
    id: string;
    name: string;
    description: string;
    vancancy: number;
    image?: string;
    state: EOfferState;
  };
}

export interface IUpdateStateOfferInput {
  id: string;
  state: EOfferState;
  publicEnd: string;
}

const UPDATE_STATE_OFFER = gql`
  mutation updateStateOffer($updateStateOfferInput: UpdateStateOfferInput!) {
    updateStateOffer(updateStateOfferInput: $updateStateOfferInput) {
      id
      name
      description
      vancancy
      image
      state
      typeOffer
      maxSalary
      currency
      requester {
        id
        name
      }
    }
  }
`;

export interface IDeleteOfferInput {
  deleteOfferInput: {
    id: string;
  };
}

const DELETE_OFFER = gql`
  mutation deleteOffer($deleteOfferInput: DeleteOfferInput!) {
    deleteOffer(deleteOfferInput: $deleteOfferInput) {
      id
    }
  }
`;

export interface ICreateProcessOfferInputRes {
  addProcessOffer: {
    processOffer: IProcessOffer[];
  };
}

export interface ICreateProcessOfferInput {
  createProcessOfferInput: {
    id: string;
    name: string;
    state?: EProcessOfferState;
  };
}

const CREATE_PROCESS_OFFER = gql`
  mutation addProcessOffer($createProcessOfferInput: CreateProcessOfferInput!) {
    addProcessOffer(createProcessOfferInput: $createProcessOfferInput) {
      processOffer {
        id
        label
        state
      }
    }
  }
`;

export interface IUpdateProcessOfferInputRes {
  updateProcessOffer: {
    processOffer: IProcessOffer[];
  };
}

export interface IUpdateProcessOfferInput {
  updateProcessOfferInput: {
    id: string;
    processOffer: IProcessOffer[];
  };
}

const UPDATE_PROCESS_OFFER = gql`
  mutation UpdateProcessOffer(
    $updateProcessOfferInput: UpdateProcessOfferInput!
  ) {
    updateProcessOffer(updateProcessOfferInput: $updateProcessOfferInput) {
      processOffer {
        id
        label
        state
      }
    }
  }
`;

export interface IDeleteProcessOfferInputRes {
  deleteProcessOffer: {
    id: string;
  };
}

export interface IDeleteProcessOfferInput {
  deleteProcessOfferInput: {
    id: string;
    idProcess: string;
  };
}

const DELETE_PROCESS_OFFER = gql`
  mutation deleteProcessOffer(
    $deleteProcessOfferInput: DeleteProcessOfferInput!
  ) {
    deleteProcessOffer(deleteProcessOfferInput: $deleteProcessOfferInput) {
      id
    }
  }
`;

export interface IUpdateNameProcessOfferInputRes {
  updateNameProcessOffer: {
    processOffer: IProcessOffer[];
  };
}

export interface IUpdateNameProcessOfferInput {
  updateNameProcessOfferInput: {
    id: string;
    idProcess: string;
    label: string;
    state?: EProcessOfferState;
  };
}

const UPDATE_NAME_PROCESS_OFFER = gql`
  mutation UpdateNameProcessOffer(
    $updateNameProcessOfferInput: UpdateNameProcessOfferInput!
  ) {
    updateNameProcessOffer(
      updateNameProcessOfferInput: $updateNameProcessOfferInput
    ) {
      processOffer {
        id
        label
        state
      }
    }
  }
`;

export interface ICreateRequestInput {
  name: string;
  description?: string;
  crmId: string;
  opportunityMargin: number;
  maxRent: number;
  currency: ECurrency;
  type?: ERequestType;
  area?: string;
  files?: IFileExtras[];
  deadline?: Date | null;
  contractType?: EEmployeeContractType;
  clientScheduleStart?: string;
  clientScheduleEnd?: string;
  projectDuration: string;
  specialShifts: boolean;
  locationRestriction: boolean;
  seniority: string;
  client: string;
  serviceType: string;
  vacancies: string;
  country: string;
  modality: string;
  techStack: string[];
  englishLevel: string;
  requiresCertificationOrDegree?: string;
  keyFunctionsAndResponsibilities?: string;
  isEquipmentProvidedByClientOrUs: boolean;
  processStagesAndClientEvaluators?: string;
  specialShiftsDetails?: string;
  locationRestrictionDetails?: string;
}

export interface ICreateRequestResponse {
  createRequest: IRequest;
}

const CREATE_REQUEST = gql`
  mutation CreateRequest($createRequestInput: CreateRequestInput!) {
    createRequest(createRequestInput: $createRequestInput) {
      id
      name
      description
      createdAt
    }
  }
`;

export interface IUpdateRequestInput extends ICreateRequestInput {
  id: string;
}

export interface IUpdateRequestResponse {
  updateRequest: IRequest;
}

const UPDATE_REQUEST = gql`
  mutation UpdateRequest($updateRequestInput: UpdateRequestInput!) {
    updateRequest(updateRequestInput: $updateRequestInput) {
      id
      name
      description
      createdAt
    }
  }
`;

export interface IDeleteRequestInput {
  id: string;
}

export interface IDeleteRequestResponse {
  deleteRequest: IRequest;
}

const DELETE_REQUEST = gql`
  mutation DeleteRequest($deleteRequestInput: DeleteRequestInput!) {
    deleteRequest(deleteRequestInput: $deleteRequestInput) {
      id
      name
      description
      createdAt
    }
  }
`;

export interface IUpdateProcessInput {
  updateProcessInput: {
    id: string;
    status: string;
  };
}

export interface IUpdateProcessInputRes {
  updateProcess: IProcess;
}

const UPDATE_PROCESS = gql`
  mutation updateProcess($updateProcessInput: UpdateProcessInput!) {
    updateProcess(updateProcessInput: $updateProcessInput) {
      id
      offerId
      status
      user {
        id
        name
        lastname
        email
        cvName
        cvUrl
        incomeExpectation
        locationId
        region
        tags
        notes {
          id
          note
          date
          createdBy
        }
      }
    }
  }
`;

const APPLICANT_LOGIN = gql`
  mutation LoginApplicant($loginInput: LoginInput!) {
    loginApplicant(loginInput: $loginInput) {
      accessToken
      refreshToken
    }
  }
`;

const APPLICANT_SIGNUP = gql`
  mutation SignupApplicant($signUpInput: CreateApplicantInput!) {
    signupApplicant(signUpInput: $signUpInput) {
      accessToken
      refreshToken
    }
  }
`;

export const PROVISORY_PASSWORD = gql`
  mutation provisoryPassword(
    $provisoryPasswordInput: ChangePasswordRequestInput!
  ) {
    provisoryPassword(provisoryPasswordInput: $provisoryPasswordInput) {
      id
      name
    }
  }
`;

export interface ICreateProcessInputRes {
  createProcess: IProcess;
}

export interface ICreateProcessInput {
  createProcessInput: {
    offerId: string;
    userId: string;
  };
}

const CREATE_PROCESS = gql`
  mutation createProcess($createProcessInput: CreateProcessInput!) {
    createProcess(createProcessInput: $createProcessInput) {
      id
      offerId
    }
  }
`;

const CREATE_PROCESS_FROM_PORTAL = gql`
  mutation CreateProcessFromPortal($createProcessInput: CreateProcessInput!) {
    createProcessFromPortal(createProcessInput: $createProcessInput) {
      id
      offerId
    }
  }
`;

export interface IUploadCv {
  name: string;
  cvInB64Format: string;
}
export interface IUploadCvInput {
  uploadCvInput: IUploadCv;
}

export interface IUploadCvRes {
  uploadCv: IApplicant;
}

const UPLOAD_CV = gql`
  mutation uploadCv($uploadCvInput: UploadCvInput!) {
    uploadCv(uploadCvInput: $uploadCvInput) {
      id
      cvUrl
      cvName
    }
  }
`;

const ASSIGN_RECRUITER = gql`
  mutation AssignRecruiter($recruiterId: String!, $requestId: String!) {
    assignRecruiter(recruiterId: $recruiterId, requestId: $requestId) {
      id
    }
  }
`;
const GENERATE_OFFERS_REPORT = gql`
  mutation GenerateOfferReport(
    $generateOfferReportInput: GenerateOfferReportInput!
  ) {
    generateOfferReport(generateOfferReportInput: $generateOfferReportInput)
  }
`;

const DOWNLOAD_CV = gql`
  mutation DownloadCv($applicantId: String!, $lang: String!) {
    downloadCv(applicantId: $applicantId, lang: $lang)
  }
`;

export const PROCESS_OFFER_TIME: DocumentNode = gql`
  mutation ProcessOfferTime($generateOfferReportInput: GetOfferIdInput!) {
    processOfferTime(generateOfferReportInput: $generateOfferReportInput)
  }
`;

export default {
  GENERATE_OFFERS_REPORT,
  CREATE_APPLICANT,
  UPDATE_APPLICANT,
  DELETE_APPLICANT,
  CREATE_OFFER,
  UPDATE_OFFER,
  UPDATE_STATE_OFFER,
  DELETE_OFFER,
  CREATE_PROCESS_OFFER,
  UPDATE_PROCESS_OFFER,
  DELETE_PROCESS_OFFER,
  UPDATE_NAME_PROCESS_OFFER,
  CREATE_REQUEST,
  UPDATE_REQUEST,
  DELETE_REQUEST,
  UPDATE_PROCESS,
  APPLICANT_LOGIN,
  APPLICANT_SIGNUP,
  PROVISORY_PASSWORD,
  CREATE_PROCESS,
  UPLOAD_CV,
  UPDATE_APPLICANT_FROM_PORTAL,
  CREATE_PROCESS_FROM_PORTAL,
  ASSIGN_RECRUITER,
  GENERATE_AI_CV,
  DOWNLOAD_CV,
  PROCESS_OFFER_TIME,
};
