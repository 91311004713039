"use client";

import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useLazyQuery } from "@apollo/client";
import { SITE_CREATION_HOSTS, THEME } from "@constants/global";
import { ESystemRoles } from "@constants/SystemRoles";
import CommandPalette, {
  JsonStructure,
  filterItems,
  getItemIndex,
  useHandleOpenCommandPalette,
} from "@tmikeladze/react-cmdk";
import "@tmikeladze/react-cmdk/dist/cmdk.css";
import { APP_VERSION } from "components/Roadmap/ItemPathNode/constants";
import UserAvatar from "components/User/UserAvatar";
import { useUserStoreContext } from "context/userStore/userStore.provider";
import { useVendor } from "context/vendor/vendor.provider";
import { gqlUser } from "gql";
import { IVendorUserListResponse } from "gql/User/queries";
import { useTheme } from "next-themes";
import { useCallback, useEffect, useState } from "react";
import { PRIVATE_ROUTES } from "routes/routes";
import { Loader } from "rsuite";
import { IUser } from "types/User.types";
import useDebounce from "utils/hooks/useDebounce";
import { IVendor } from "types/Vendor.types";
import Icon from "components/Icon";

export interface MenuItem {
  id: string;
  children: string;
  icon: React.ReactNode;
  type?: string;
  closeOnSelect?: boolean;
  onClick: () => void;
}

export interface MenuSection {
  heading: string;
  id: string;
  items: (MenuItem | false)[];
}

export const hasAdminAccess = (role?: ESystemRoles) =>
  [ESystemRoles.ADMIN, ESystemRoles.LEADER].includes(role as ESystemRoles);

export const hasJobyfineAccess = (user?: IUser) =>
  user?.jobyfineAdmin || user?.jobyfineRecruiter || user?.jobyfineRequester;

export const generateMenuStructure = (
  t: any,
  user?: IUser,
  vendor?: IVendor,
  onPageChange?: (page: string) => void,
  onNavigate?: (path: string) => void
): MenuSection[] => [
  {
    heading: t("Atajos"),
    id: "manager",
    items: [
      {
        id: "search-users",
        children: t("Buscar usuarios"),
        icon: <Icon icon={["far", "search"]} />,
        closeOnSelect: false,
        onClick: () => onPageChange?.("users"),
      },
      hasAdminAccess(user?.systemRole) && {
        id: "users",
        children: t("Ir a usuarios"),
        icon: <Icon icon={["far", "user"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.userMaintainer.path),
      },
      hasAdminAccess(user?.systemRole) && {
        id: "evaluations",
        children: t("Ir a evaluaciones"),
        icon: <Icon icon={["far", "book"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.appreciationList.path),
      },
      hasAdminAccess(user?.systemRole) && {
        id: "areas",
        children: t("Ir a areas"),
        icon: <Icon icon={["far", "user-group"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.areaMaintainer.path),
      },
      hasAdminAccess(user?.systemRole) && {
        id: "skills",
        children: t("Ir a habilidades"),
        icon: <Icon icon={["far", "gear"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.dimensionMaintainer.path),
      },
      hasAdminAccess(user?.systemRole) && {
        id: "roles",
        children: t("Ir a cargos"),
        icon: <Icon icon={["far", "stairs"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.roleMaintainer.path),
      },
      hasAdminAccess(user?.systemRole) && {
        id: "teams",
        children: t("Ir a equipos"),
        icon: <Icon icon={["far", "user-cog"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.teamsLeader.path),
      },
      user?.systemRole === ESystemRoles.ADMIN && {
        id: "radar",
        children: t("Ir a radar"),
        icon: <Icon icon="radar" />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.radar.path),
      },
      vendor?.enableCoins && {
        id: "marketplace",
        children: t("Ir a marketplace"),
        icon: <Icon icon={["far", "coins"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.myAppreciations.path),
      },
      vendor?.enableActionPlans && {
        id: "action-plan",
        children: t("Ir a plan de acción"),
        icon: <Icon icon={["far", "plane"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.actionPlans.path),
      },
      {
        id: "my-evaluations",
        children: t("Ir a mis evaluaciones"),
        icon: <Icon icon={["far", "leafy-green"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.myAppreciations.path),
      },
      vendor?.enableActionPlans && {
        id: "my-action-plans",
        children: t("Ir a mis planes de acción"),
        icon: <Icon icon={["far", "plane-alt"]} />,
        type: "button",
        onClick: () => onNavigate?.(PRIVATE_ROUTES.userActionPlans.path),
      },
      vendor?.enableJobyfine &&
        hasJobyfineAccess(user) && {
          id: "jobyfine",
          children: t("Ir a Jobyfine"),
          icon: <Icon icon={["far", "user-group-crown"]} />,
          type: "button",
          onClick: () => onNavigate?.(PRIVATE_ROUTES.jobyfineApplicants.path),
        },
    ].filter(Boolean) as MenuItem[],
  },
];

const CommandPallete = () => {
  const { user } = useUserStoreContext((store) => ({
    user: store.user,
  }));

  const { commandPalleteOpen: commandPaletteOpen } = useUserStoreContext(
    (store) => ({
      commandPalleteOpen: store.commandPaletteOpen,
    })
  );

  const toggleCommandPalette = useUserStoreContext(
    (store) => store.toggleCommandPalette
  );

  const { t } = useTranslation("common");
  const router = useRouter();
  const [page, setPage] = useState<"root" | "users">("root");

  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState<JsonStructure>([]);
  const { theme } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>();
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const { vendor } = useVendor();

  const [getActiveUsers] = useLazyQuery<IVendorUserListResponse>(
    gqlUser.queries.GET_VENDOR_USER_LIST_LIGHT,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ vendorUserList }) => {
        if (vendorUserList) {
          setLoading(false);
          setUsers(vendorUserList);
        } else {
          setUsers([]);
        }
      },
    }
  );

  const handleSearchTerm = (input: string) => {
    if (input.length > 1) {
      setLoading(true);
      setSearchTerm(input);
    } else {
      setLoading(false);
      if (input.length === 0) setUsers([]);
    }
  };

  const handlePageChange = (page: string) => {
    setPage(page as "root" | "users");
  };

  const handleNavigate = (path: string) => {
    router.push(path);
  };

  const handleSearch = useCallback(
    (input: string) => {
      setSearch(input);

      if (page === "users") {
        handleSearchTerm(input);
        return;
      }

      if (!user || !vendor) return;

      const structure = generateMenuStructure(
        t,
        user,
        vendor,
        handlePageChange,
        handleNavigate
      );
      setFilteredItems(
        filterItems(structure?.filter(Boolean) as JsonStructure, input)
      );
    },
    [page, user, vendor, t]
  );

  useEffect(() => {
    if (SITE_CREATION_HOSTS.includes(window.location.host)) return;
    if (!user || !vendor) return;

    const structure = generateMenuStructure(
      t,
      user,
      vendor,
      handlePageChange,
      handleNavigate
    );
    setFilteredItems(
      filterItems(structure.filter(Boolean) as JsonStructure, search)
    );
  }, [user, vendor, search, t]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      getActiveUsers({
        variables: {
          vendorUserListInput: {
            searchText: searchTerm,
          },
        },
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!commandPaletteOpen) {
      setSearch("");
      setPage("root");
    }
  }, [commandPaletteOpen]);

  useHandleOpenCommandPalette(toggleCommandPalette);

  // * PORTAL: Career
  if (!user) return null;

  return (
    <CommandPalette
      onChangeSearch={handleSearch}
      onChangeOpen={toggleCommandPalette}
      search={search}
      isOpen={commandPaletteOpen}
      page={page}
      commandPaletteContentClassName={theme === THEME.dark ? "dark" : undefined}
      footer={
        <div className="flex justify-between" style={{ paddingInline: "1rem" }}>
          <span className="text-xs">Groowcity Commands</span>
          <span className="text-xs">{APP_VERSION}</span>
        </div>
      }
    >
      <CommandPalette.Page id="root">
        {filteredItems.length ? (
          filteredItems.map((list) => (
            <CommandPalette.List key={list.id} heading={list.heading}>
              {list.items.map(({ id, onClick, children, type }) => (
                <CommandPalette.ListItem
                  key={id}
                  index={getItemIndex(filteredItems, id)}
                  onClick={onClick}
                  type={type}
                >
                  {children}
                </CommandPalette.ListItem>
              ))}
            </CommandPalette.List>
          ))
        ) : (
          <CommandPalette.FreeSearchAction />
        )}
      </CommandPalette.Page>
      <CommandPalette.Page
        searchPrefix={["Users"]}
        id="users"
        onEscape={() => {
          setPage("root");
        }}
      >
        <CommandPalette.List>
          {!loading &&
            users?.map((user, index) => (
              <CommandPalette.ListItem
                key={user.id}
                index={index}
                onClick={() => {
                  router.push(`/profile/${user?.id}?vendorId=${vendor?.id}`);
                }}
              >
                <div className="flex items-center">
                  <UserAvatar {...{ user, showName: false, size: 40 }} />
                  <div className="align-center ml-3 flex flex-col justify-start">
                    <span className="capitalize">
                      {user.name} {user.lastname}
                    </span>
                    <p className="block text-xs capitalize text-gray-800 dark:text-gray-300">
                      {user.role?.name || "-"}
                    </p>
                    <p className="block text-xs lowercase text-gray-600 dark:text-gray-200">
                      {user.email}
                    </p>
                  </div>
                </div>
              </CommandPalette.ListItem>
            ))}

          {loading && (
            <Loader
              className="py-4"
              content={t("Buscando entre los colaboradores")}
            />
          )}

          {!loading && users?.length === 0 && (
            <div className="flex items-center py-4">
              <span className="text-xs">
                {t("No se encontraron resultados")}
              </span>
            </div>
          )}
        </CommandPalette.List>
      </CommandPalette.Page>
    </CommandPalette>
  );
};

export default CommandPallete;
