module.exports = {
  logBuild: false,
  locales: ["en", "es"],
  defaultLocale: "en",
  pages: {
    "*": [
      "common",
      "vacation",
      "celebration",
      "appreciation",
      "explore",
      "vendor",
    ],
  },
};
