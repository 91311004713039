import { gql } from "@apollo/client";
import { IVendor } from "types/Vendor.types";
import { TQuickiesTypes } from "../../context/quickie/quickie.types";

const VALIDATE_VENDOR_NAME = gql`
  query Query($name: String!, $captcha: String!) {
    validateVendorName(name: $name, captcha: $captcha)
  }
`;

const VENDOR = gql`
  query VendorByHostname {
    vendorByHostname {
      id
      name
      slug
      description
      address {
        address
        lat
        lng
      }
      domains
      tenantId
      clientId
      onlyAdLogin
      logo
      loginBackgroundImage
      enableLoginWithLinkedin
      enableMicrosoftLogin
      enableExplore
      enableFlokzu
      enableAdSync
      enableBukIntegration
      enableCoins
      enableAnonymousBox
      enableChallenges
      enableActionPlans
      enableCareerPlan
      enableJobyfine
      enableForms
      enableVacations
      enableBirthdayAndAnniversary
      enableGoals
      enableCorporateValue
      jobyfineResumeBanner
      isFullAccessEnabled
      enableCertificates
      vendorLinks {
        id
        name
        url
        icon
      }
    }
  }
`;

export interface ISelectedVendorResponse {
  selectedVendor: IVendor;
}

export const GET_SELECTED_VENDOR = gql`
  query selectedVendor {
    selectedVendor {
      id
      createdAt
      updatedAt
      slug
      name
      phone
      status
      logo
      enableCareerPlan
      enableJobyfine
      enableBukIntegration
      enableCoins
      enableCareerPlan
      enableAdSync
      enableChallenges
      enableAnonymousBox
      enableActionPlans
      enableFlokzu
      enableExplore
      enableMicrosoftLogin
      enableLoginWithLinkedin
      enableForms
      enableVacations
      enableBirthdayAndAnniversary
      enableGoals
      enableCorporateValue
      enableUrlOnMails
      enableCertificates
      corporateValuesMaxRecognitions
      usersWithoutRecognitionRestrictions
      jobyfineHiringManagers
      jobyfineNotificationEmails
      jobyfinePortalSlogan
      jobyfinePortalDescription
      jobyfinePortalTitle
      jobyfinePortalUrl
      jobyfineResumeBanner
      happyBirthdayPrompt
      anniversaryPrompt
      certificateUserPrompt
      emailDomains
      loginBackgroundImage
      isFullAccessEnabled
      tenantId
      clientId
      clientSecret
      onlyAdLogin
      domains
      identifier
      banners {
        title
        description
        bannerUrl
        cta
        btnColor
        btnText
        align
        modal
      }
      slogan
      email
      description
      address {
        address
        lat
        lng
      }
      apiKeys {
        name
        permissions
        key
        createdAt
      }
    }
  }
`;

export interface IQuickiesInput {
  getQuickiesInput: {
    type: TQuickiesTypes;
  };
}

export interface VendorQuickie {
  id: string;
  name: string;
}

export interface IQuickiesRes {
  quickies: VendorQuickie[];
}

const GET_QUICKIES = gql`
  query quickies($getQuickiesInput: GetQuickiesInput!) {
    quickies(getQuickiesInput: $getQuickiesInput) {
      id
      name
    }
  }
`;

const GET_VENDOR_BANNERS = gql`
  query selectedVendor {
    selectedVendor {
      banners {
        title
        description
        bannerUrl
        cta
        btnColor
        btnText
        align
        modal
      }
    }
  }
`;

const GET_VENDOR_SENIORITY_STATS = gql`
  query seniorityStats {
    seniorityStats {
      seniority
      quantity
      users {
        id
        name
        lastname
        profileImage
        email
        userLeaders {
          id
          name
          lastname
          profileImage
          email
        }
      }
    }
  }
`;

const GET_VENDOR_USER_CERTIFICATIONS = gql`
  query vendorUsersCertifications {
    vendorUsersCertifications {
      user {
        id
        name
        lastname
        email
        profileImage
        certifications {
          type
        }
        role {
          name
        }
      }
      quantity
      quantityOutOfDate
      lastUpload
      unix
    }
  }
`;

const GET_VENDOR_USER_CERTIFICATIONS_PAGINATED = gql`
  query VendorUsersCertificationsPaginated(
    $vendorUserCertificationsInput: VendorUserCertificationsInput!
  ) {
    vendorUsersCertificationsPaginated(
      VendorUserCertificationsInput: $vendorUserCertificationsInput
    ) {
      userCertifications {
        user {
          id
          name
          lastname
          email
          profileImage
          certifications {
            type
          }
          role {
            name
          }
        }
        lastUpload
        quantity
        quantityOutOfDate
        unix
      }
      total
    }
  }
`;

const CERTIFICATION_STATS = gql`
  query VendorCertificationsStats {
    vendorCertificationsStats {
      quantity
      certificationType
    }
  }
`;

const CERTIFICATION_TYPES_STATS = gql`
  query VendorTypeCertificationsStats {
    vendorTypeCertificationsStats {
      typeCertification
      quantity
    }
  }
`;

const GET_VENDOR_RADAR = gql`
  query vendorRadar($getVendorRadarInput: GetVendorRadarInput!) {
    vendorRadar(getVendorRadarInput: $getVendorRadarInput) {
      radar {
        id
        name
        ring
        quadrant
        quantity
      }
      seniorityQuantity {
        total
        junior
        totalJunior
        semiSenior
        totalSemiSenior
        senior
        totalSenior
        expert
        totalExpert
        none
        totalNone
        totalUsers
        billableUsers
        nonBillableUsers
        utilizationRate
        totalMale
        totalFemale
        globalJunior
        globalSemiSenior
        globalSenior
        globalExpert
        globalUndefined
        lastSyncBuk
        lastSyncCrm
      }
    }
  }
`;

const GET_PUBLIC_VENDOR_RADAR = gql`
  query publicRadar($getVendorRadarInput: GetVendorRadarInput!) {
    publicRadar(getVendorRadarInput: $getVendorRadarInput) {
      radar {
        id
        name
        ring
        quadrant
        quantity
      }
      seniorityQuantity {
        total
        junior
        totalJunior
        semiSenior
        totalSemiSenior
        senior
        totalSenior
        expert
        totalExpert
        none
        totalNone
      }
    }
  }
`;

const FILTERED_COUNTRIES = gql`
  query VendorCountryList {
    vendorCountryList {
      value
      label
    }
  }
`;

const GET_PROJECTS_BY_USER = gql`
  query vendorProjectListByUserId($userId: String!) {
    vendorProjectListByUserId(userId: $userId) {
      id
      externalId
      businessUnit
      name
      startDate
      endDate
    }
  }
`;

const GET_ADVICE = gql`
  query getAdvice($openAIInput: OpenAIInput!) {
    getAdvice(openAIInput: $openAIInput) {
      result
    }
  }
`;

const AREA_ANALYSIS = gql`
  query AreasAnalysis {
    areasAnalysis {
      users {
        id
        name
        lastname
        email
        profileImage
        assignable
        assigned
        gender
        seniority
        role {
          id
          name
        }
      }
      area {
        id
        name
        avatar
      }
    }
  }
`;

const GET_CLIENTS = gql`
  query VendorClientList {
    vendorClientList {
      name
      id
      country
      countryName
    }
  }
`;

const VENDORS = gql`
  query Vendors {
    vendors {
      id
      name
      createdAt
    }
  }
`;

export interface IGetVendorResponse {
  vendor: IVendor;
}

const VENDOR_LINKS = gql`
  query GetVendorLinks {
    getVendorLinks {
      id
      order
      name
      icon
      url
    }
  }
`;

export default {
  GET_SELECTED_VENDOR,
  GET_QUICKIES,
  GET_VENDOR_BANNERS,
  GET_VENDOR_SENIORITY_STATS,
  GET_VENDOR_USER_CERTIFICATIONS,
  GET_VENDOR_RADAR,
  GET_PUBLIC_VENDOR_RADAR,
  FILTERED_COUNTRIES,
  GET_PROJECTS_BY_USER,
  GET_ADVICE,
  AREA_ANALYSIS,
  GET_CLIENTS,
  VENDORS,
  VALIDATE_VENDOR_NAME,
  VENDOR,
  GET_VENDOR_USER_CERTIFICATIONS_PAGINATED,
  CERTIFICATION_STATS,
  CERTIFICATION_TYPES_STATS,
  VENDOR_LINKS,
};
