import { USER_TOKEN_PERSIST } from "@constants/global";

interface IBlobToBase {
  blob: Blob;
}

export const blobToBase64 = ({ blob }: IBlobToBase): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      resolve(reader.result as string);
    };
    reader.onerror = async (e) => {
      reject(e);
    };
    reader.readAsDataURL(blob);
  });

// *****************************************
// * Trigger download from blob or url
// *****************************************
interface IDownloadFile {
  blob?: Blob;
  fileUrl?: string;
  fileName: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}
export const downloadFile = ({
  blob,
  fileUrl,
  fileName,
  target = "_self",
}: IDownloadFile) => {
  const href = blob ? window.URL.createObjectURL(blob) : fileUrl;
  const anchor = document.createElement("a");
  if (href) {
    anchor.setAttribute("href", href);
  }
  anchor.setAttribute("download", fileName);
  anchor.setAttribute("target", target);
  document.body.appendChild(anchor);
  anchor.click();
  if (anchor.parentNode) {
    anchor.parentNode.removeChild(anchor);
  }
};

export const downloadFileFromUrl = (url: string, name: string) => {
  const anchor = document.createElement("a");
  anchor.setAttribute("href", url);
  anchor.setAttribute("download", name);
  document.body.appendChild(anchor);
  anchor.click();
  if (anchor.parentNode) {
    anchor.parentNode.removeChild(anchor);
  }
};

export const readFile = (file: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(`${reader.result}`), false);
    reader.readAsDataURL(file);
  });

interface IBlobToFile {
  blob: Blob;
  fileName: string;
}
export const blobToFile = (params: IBlobToFile): File => {
  const { blob, fileName } = params;

  const b: any = blob;
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  // Cast to a File() type
  return blob as File;
};

// *****************************************
// * Upload Image
// *****************************************
interface IUploadImage {
  imgb64: string;
}

export const uploadImage = async ({
  imgb64,
}: IUploadImage): Promise<string> => {
  const prepareUrl = (process.env.NEXT_PUBLIC_APP_API_URL ?? "").replace(
    "/graphql",
    ""
  );

  const res = await fetch(`${prepareUrl}/question/uploadImages`, {
    method: "POST",
    headers: {
      "x-api-key": localStorage.getItem(USER_TOKEN_PERSIST) || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ imgb64 }),
  });

  return await res.text();
};

export const decodeBase64 = (base64: string): string | null => {
  try {
    return atob(base64);
  } catch (e) {
    return null;
  }
};
