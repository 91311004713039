import React, { createContext, ReactNode, useContext } from "react";
import useTemplateStore, {
  TemplateType,
} from "stores/template/useTemplateStore";

// Tipo para las props del proveedor del contexto
interface TemplateProviderProps {
  children: ReactNode;
}

// Crear el contexto para el template
const TemplateContext = createContext<
  | {
      template: TemplateType;
      setTemplate: (template: TemplateType) => void;
      toggleTemplate: () => void;
    }
  | undefined
>(undefined);

// Crear el proveedor del contexto
export const TemplateProvider: React.FC<TemplateProviderProps> = ({
  children,
}) => {
  const { template, setTemplate, toggleTemplate } = useTemplateStore(
    (state) => ({
      template: state.template,
      setTemplate: state.setTemplate,
      toggleTemplate: state.toggleTemplate,
    })
  );

  return (
    <TemplateContext.Provider value={{ template, setTemplate, toggleTemplate }}>
      {children}
    </TemplateContext.Provider>
  );
};

// Hook para consumir el contexto
export const useTemplateContext = () => {
  const context = useContext(TemplateContext);
  if (!context) {
    throw new Error(
      "useTemplateContext must be used within a TemplateProvider"
    );
  }
  return context;
};
