import { useWindowWidth } from "@react-hook/window-size";
import { IMenuList } from "components/Layout/ManagerProLayout/utils";
import { useTemplateContext } from "context/template/TemplateContext";
import { useIsScreenMd } from "hooks/useIsScreenMd";
import { ChevronRight } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { IRoute } from "routes/types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "shadcn/components/ui/accordion";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "shadcn/components/ui/dropdown-menu";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import { TemplateType } from "stores/template/useTemplateStore";
import { ICONS_ACTIVE_VERSION, isSvg } from "../content/utils/iconModulesPath";

interface MenuDropdownItemProps {
  item: IMenuList;
  onRedirect: (path: string) => void;
  checkSubRoutesRoleGuard: (routes: IRoute[]) => boolean;
  evaluateSubRoutesIncludesPath: (pathname: string, item: IMenuList) => boolean;
  isHovered: boolean;
  onHover: () => void;
  setIsHoverItem: (value: boolean) => void;
  openItemIndex: number | null; // Recibe el índice del item abierto
  setOpenItemIndex: (index: number | null) => void; // Función para actualizar el índice abierto
  index: number; // Recibe el índice del item actual
}

export default function MenuDropdownItem(props: MenuDropdownItemProps) {
  const {
    item,
    onRedirect,
    checkSubRoutesRoleGuard,
    evaluateSubRoutesIncludesPath,
    isHovered,
    onHover,
    setIsHoverItem,
    openItemIndex,
    setOpenItemIndex,
    index,
  } = props;
  const router = useRouter();
  const { isExpanded } = useDesktopSidebarStore();
  const wWidth = useWindowWidth();
  const [renderText, setRenderText] = useState(true);
  const [renderExpandedComponent, setRenderExpandedComponent] = useState(true);
  const [showDropdown, setShowDropdown] = useState(true);
  const isScreenMd = useIsScreenMd();
  const [openItem, setOpenItem] = useState<string | null>(null);

  const hasAccess = !checkSubRoutesRoleGuard(
    item?.children
      ?.map((route) => route?.route)
      .filter((route): route is IRoute => route !== undefined) || []
  );

  const { template } = useTemplateContext();

  useEffect(() => {
    if (!isExpanded && isScreenMd) {
      setTimeout(() => {
        setRenderText(false);
        setRenderExpandedComponent(false);
      }, 200);
    }
    if (isExpanded && isScreenMd) {
      setRenderText(true);
      setRenderExpandedComponent(true);
    }
    if (!isScreenMd) {
      setRenderText(true);
      setRenderExpandedComponent(true);
    }
    if (
      isScreenMd &&
      isExpanded &&
      evaluateSubRoutesIncludesPath(router.pathname, item) &&
      openItemIndex === index
    ) {
      setOpenItem("item-1");
    } else {
      setOpenItem(null);
    }
  }, [isExpanded, isScreenMd, router.pathname, index, wWidth]);

  return hasAccess ? (
    <>
      {renderExpandedComponent ? (
        <Accordion
          type="single"
          collapsible
          value={openItem ?? undefined}
          onValueChange={(value) => {
            setOpenItem(value);
            setOpenItemIndex(value === null ? null : index);
          }}
        >
          <AccordionItem value="item-1" className="w-full border-b-0">
            <AccordionTrigger
              className={`h-sidebar-button w-full py-0 pl-6 pr-3 font-normal hover:no-underline ${
                template === TemplateType.Classic &&
                !evaluateSubRoutesIncludesPath(router.pathname, item) &&
                "hover:bg-gray-100 hover:dark:bg-slate-800"
              } ${
                template === TemplateType.Classic &&
                evaluateSubRoutesIncludesPath(router.pathname, item) &&
                "active bg-gray-200 dark:bg-gray-800"
              } ${
                template === TemplateType.Aurora &&
                !evaluateSubRoutesIncludesPath(router.pathname, item) &&
                "hover:rounded-2xl hover:bg-gray-100 hover:dark:bg-slate-800"
              } ${
                template === TemplateType.Aurora &&
                evaluateSubRoutesIncludesPath(router.pathname, item) &&
                "active rounded-2xl bg-gray-200 dark:bg-gray-800"
              } `}
            >
              <div className="flex h-full w-full items-center gap-4">
                <picture className="relative grid min-h-sidebar-icon min-w-sidebar-icon">
                  <Image
                    src={item.urlIcon}
                    fill
                    sizes="w-sidebar-icon"
                    className={`h-sidebar-icon w-sidebar-icon object-contain ${ICONS_ACTIVE_VERSION() === "v2" && "opacity-50 dark:invert"}`}
                    alt={item.name}
                    priority
                    unoptimized={isSvg(item.urlIcon)}
                  />
                </picture>
                {renderText && (
                  <span className="block overflow-hidden truncate text-sidebar">
                    {item.name}
                  </span>
                )}
              </div>
            </AccordionTrigger>
            {item?.children?.map((route) =>
              route.route
                ? !checkSubRoutesRoleGuard([route.route]) && (
                    <AccordionContent
                      className="my-1 grid h-sidebar-dropdown-button w-full items-center gap-4 pb-0 pl-6 pr-4 text-left"
                      key={route.route.path}
                    >
                      <div
                        className={`flex h-full w-full cursor-pointer gap-4 rounded-lg pl-4 ${
                          router.pathname !== route.route.path &&
                          "hover:bg-gray-100 hover:dark:bg-slate-800"
                        } ${
                          router.pathname === route.route.path
                            ? "active rounded-2xl bg-gray-200 dark:bg-gray-800"
                            : ""
                        }`}
                        onClick={() => onRedirect(route?.route?.path || "")}
                      >
                        <picture className="relative grid min-h-sidebar-dropdown-icon min-w-sidebar-dropdown-icon">
                          <Image
                            src={route.urlIcon}
                            fill
                            sizes="w-sidebar-dropdown-icon"
                            className={`h-sidebar-dropdown-icon w-sidebar-dropdown-icon object-contain ${ICONS_ACTIVE_VERSION() === "v2" && "opacity-50 dark:invert"}`}
                            alt={item.name}
                            priority
                            unoptimized={isSvg(route.urlIcon)}
                          />
                        </picture>

                        <span className="grid h-full w-full items-center text-sidebar-submenu">
                          {route.name}
                        </span>
                        {route.beta && (
                          <span className="ml-2 rounded bg-blue-200 p-1 text-xs text-blue-800">
                            Beta
                          </span>
                        )}
                      </div>
                    </AccordionContent>
                  )
                : null
            )}
          </AccordionItem>
        </Accordion>
      ) : (
        <DropdownMenu open={isHovered && showDropdown} modal={false}>
          <DropdownMenuTrigger
            onMouseOver={() => {
              onHover(); // Llama a la primera función
              setIsHoverItem(false); // Llama a la segunda función
              setShowDropdown(true);
            }}
            className={`relative flex h-sidebar-button w-full items-center gap-4 pl-6 focus-visible:outline-none ${
              !evaluateSubRoutesIncludesPath(router.pathname, item) &&
              "hover:bg-gray-100 hover:dark:bg-slate-800"
            } ${
              evaluateSubRoutesIncludesPath(router.pathname, item) &&
              "active bg-gray-200 dark:bg-gray-800"
            } ${template === TemplateType.Aurora && "rounded-2xl hover:rounded-2xl"}`}
          >
            <>
              <div className="flex h-full items-center">
                <picture className="relative grid min-h-sidebar-icon min-w-sidebar-icon">
                  <Image
                    src={`${item.urlIcon}`}
                    fill
                    sizes="w-sidebar-icon"
                    className={`h-sidebar-icon w-sidebar-icon object-contain ${ICONS_ACTIVE_VERSION() === "v2" && "opacity-50 dark:invert"}`}
                    alt={item.name}
                    priority
                  />
                </picture>
              </div>

              <div className="absolute right-1 top-4">
                <ChevronRight strokeWidth={1} size={12} />
              </div>
            </>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            id={`dropdown-item-${item.name}`}
            side="right"
            align="center"
            className="ml-1 bg-white dark:bg-gray-900"
            onMouseLeave={() => setShowDropdown(false)}
          >
            <DropdownMenuLabel>{item.name}</DropdownMenuLabel>
            <DropdownMenuSeparator className="bg-neutral-200" />
            {item?.children?.map((route) =>
              route.route
                ? !checkSubRoutesRoleGuard([route.route]) && (
                    <DropdownMenuItem
                      className={`grid w-full items-center gap-4 py-1 text-left ${
                        router.pathname !== route.route.path
                          ? "hover:bg-gray-100 hover:dark:bg-slate-800"
                          : ""
                      } ${
                        router.pathname === route.route.path
                          ? "active rounded-sm bg-gray-200 dark:bg-gray-800"
                          : ""
                      }`}
                      key={route.route.path}
                    >
                      <div
                        className="flex h-[36px] w-full cursor-pointer gap-2"
                        onClick={() => onRedirect(route?.route?.path || "")}
                      >
                        <picture className="relative grid min-h-sidebar-dropdown-icon min-w-sidebar-dropdown-icon">
                          <Image
                            src={`${route.urlIcon}`}
                            fill
                            sizes="25px"
                            className={`h-[25px] w-[25px] object-contain ${ICONS_ACTIVE_VERSION() === "v2" && "opacity-50 dark:invert"}`}
                            alt={route.name}
                            priority
                          />
                        </picture>
                        <span className="grid h-full w-full items-center text-sidebar-submenu">
                          {route.name}
                        </span>
                        {route.beta && (
                          <span className="ml-2 rounded bg-blue-200 p-1 text-xs text-blue-800">
                            Beta
                          </span>
                        )}
                      </div>
                    </DropdownMenuItem>
                  )
                : null
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  ) : null;
}
