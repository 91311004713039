import { gql } from "@apollo/client";

const MESSAGE_CREATED = gql`
  subscription messageCreated($vendor: String!) {
    messageCreated(vendor: $vendor) {
      id
      type
      text
      question
      tags

      corporateValue {
        id
        title
        avatar
      }

      userWhoGivesCorporateValue {
        id
        name
        lastname
        profileImage
        email
        role {
          id
          name
        }
      }
      createdBy {
        id
        name
        lastname
        profileImage
        email
        birthday
        activeSince
        role {
          id
          name
        }
      }
      questionOptions
      answer {
        id
        text
        votes
      }
      comments {
        id
        text
        createdAt
        createdBy {
          id
          name
          lastname
          profileImage
        }
      }
      reactions {
        emoji
        user
      }
    }
  }
`;

const COMMENT_CREATED = gql`
  subscription commentCreated($message: String!) {
    commentCreated(message: $message) {
      id
      text
      createdAt
      createdBy {
        id
        name
        lastname
        profileImage
      }
    }
  }
`;

export default { MESSAGE_CREATED, COMMENT_CREATED };
