import { gql } from "@apollo/client";
import { EMultimediaType, IMultimediaMessage } from "types/Multimedia.type";

export interface ICreateMultimediaInput {
  name: string;
  description: string;
  tagsNames: string[];
  fileBase64: string;
  thumbnailBase64: string;
  multimediaType: EMultimediaType;
  shortDescription?: string;
}

export interface IUpdateMultimediaInput {
  id: string;
  name: string;
  description: string;
  tagsNames: string[];
  fileBase64?: string;
  thumbnailBase64?: string;
  multimediaType: EMultimediaType;
  shortDescription?: string;
}

const CREATE_MULTIMEDIA = gql`
  mutation createMultimedia($createMultimediaInput: CreateMultimediaInput!) {
    createMultimedia(createMultimediaInput: $createMultimediaInput) {
      id
    }
  }
`;

const UPDATE_MULTIMEDIA = gql`
  mutation updateMultimedia($updateMultimediaInput: UpdateMultimediaInput!) {
    updateMultimedia(updateMultimediaInput: $updateMultimediaInput) {
      id
    }
  }
`;

const DELETE_MULTIMEDIA = gql`
  mutation deleteMultimedia($id: String!) {
    deleteMultimedia(id: $id) {
      name
    }
  }
`;

export interface IMultimediaReviewInput {
  multimediaDistributeId: string;
  score: number;
  comment?: string;
}

const CREATE_MULTIMEDIA_REVIEW = gql`
  mutation createMultimediaReview(
    $createMultimediaReviewInput: CreateMultimediaReviewInput!
  ) {
    createMultimediaReview(
      createMultimediaReviewInput: $createMultimediaReviewInput
    ) {
      user {
        name
      }
    }
  }
`;

const FINALIZE_MULTIMEDIA_BY_ID = gql`
  mutation finishedCourseMultimediaDistribute($idMaterialDistribute: String!) {
    finishedCourseMultimediaDistribute(
      idMaterialDistribute: $idMaterialDistribute
    )
  }
`;

export interface IAddMessageToMultimediaInput {
  message: string;
  multimediaId: string;
}

export interface IAddMessageToMultimediaResponse {
  addMessageToMultimedia: IMultimediaMessage;
}

const ADD_MESSAGE_TO_MULTIMEDIA = gql`
  mutation addMessageToMultimedia(
    $addMessageToMultimediaInput: AddMessageToMultimediaInput!
  ) {
    addMessageToMultimedia(
      addMessageToMultimediaInput: $addMessageToMultimediaInput
    ) {
      id
      message
      multimediaId
      createdAt
      user {
        profileImage
        name
        lastname
        createdAt
        id
      }
    }
  }
`;

export interface IUpdateMessageInMultimediaInput {
  messageId: string;
  multimediaId: string;
  message: string;
}

export interface IUpdateMessageInMultimediaResponse {
  updateMultimediaMessage: IMultimediaMessage;
}

const UPDATE_MESSAGE_IN_MULTIMEDIA = gql`
  mutation updateMultimediaMessage(
    $updateMessageInMultimediaInput: UpdateMessageInMultimediaInput!
  ) {
    updateMultimediaMessage(
      updateMessageInMultimediaInput: $updateMessageInMultimediaInput
    ) {
      id
      message
      multimediaId
      createdAt
      user {
        profileImage
        name
        lastname
        createdAt
        id
      }
    }
  }
`;

export interface IDeleteMessageInMultimediaInput {
  messageId: string;
}

const DELETE_MESSAGE_IN_MULTIMEDIA = gql`
  mutation deleteMultimediaMessage(
    $deleteMessageInMultimediaInput: DeleteMessageInMultimediaInput!
  ) {
    deleteMultimediaMessage(
      deleteMessageInMultimediaInput: $deleteMessageInMultimediaInput
    ) {
      id
      vendorId
      message
    }
  }
`;

export interface IUpdateMultimediaRateInput {
  multimediaId: string;
  rate: number;
}

const UPDATE_MULTIMEDIA_RATE = gql`
  mutation updateMultimediaRate(
    $updateMultimediaRateInput: UpdateMultimediaRateInput!
  ) {
    updateMultimediaRate(
      updateMultimediaRateInput: $updateMultimediaRateInput
    ) {
      id
      rate
    }
  }
`;

const UPDATE_MULTIMEDIA_STATE = gql`
  mutation UpdateMultimediaStates($multimediaId: String!) {
    updateMultimediaStates(multimediaId: $multimediaId) {
      id
    }
  }
`;

export interface IUploadDocumentInput {
  fileBase64: string;
}

export interface IUploadDocumentResponse {
  uploadDocument: string;
}

const UPLOAD_DOCUMENT = gql`
  mutation UploadDocument($uploadDocumentInput: UploadDocumentInput!) {
    uploadDocument(uploadDocumentInput: $uploadDocumentInput)
  }
`;

const SHAREMULTIMEDIA = gql`
  mutation ShareMultimedia($shareMultimediaInput: ShareMultimediaInput!) {
    shareMultimedia(shareMultimediaInput: $shareMultimediaInput) {
      id
    }
  }
`;

const DELETE_USER_SHARED = gql`
  mutation DeleteMultimediaUser($multimediaId: String!, $userId: String!) {
    deleteMultimediaUser(multimediaId: $multimediaId, userId: $userId) {
      id
    }
  }
`;

export default {
  UPDATE_MULTIMEDIA,
  CREATE_MULTIMEDIA,
  DELETE_MULTIMEDIA,
  CREATE_MULTIMEDIA_REVIEW,
  FINALIZE_MULTIMEDIA_BY_ID,
  ADD_MESSAGE_TO_MULTIMEDIA,
  UPDATE_MESSAGE_IN_MULTIMEDIA,
  DELETE_MESSAGE_IN_MULTIMEDIA,
  UPDATE_MULTIMEDIA_RATE,
  UPDATE_MULTIMEDIA_STATE,
  UPLOAD_DOCUMENT,
  SHAREMULTIMEDIA,
  DELETE_USER_SHARED,
};
