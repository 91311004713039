import { gql } from "@apollo/client";

export interface IGetAllCertificate {
  getAllCertificate: ICertificate[];
}

export interface ICertificate {
  id: string;
  name: string;
  customImage: string | undefined;
  subTitle: string;
  durationText?: string;
  startDate?: Date;
  totalCertificatedUsers?: number;
  footerTextTitle?: string;
  learningOutcomes?: string[];
  allowViewCourse?: boolean;
  certificateLabel?: string;
  courseUrl?: string;
  overrideVendorImage?: string;
  certificateTopLabel?: string;
  buttonText?: string;
  textMoreAboutCertificate?: string;
  titleTextMoreAboutCertificate?: string;
}

export const GET_CERTIFICATES = gql`
  query GetAllCertificate {
    getAllCertificate {
      id
      name
      customImage
      subTitle
      totalCertificatedUsers
      durationText
      startDate
      footerTextTitle
      learningOutcomes
      certificateLabel
      allowViewCourse
      courseUrl
      overrideVendorImage
      certificateTopLabel
      buttonText
      textMoreAboutCertificate
      titleTextMoreAboutCertificate
    }
  }
`;

export default {
  GET_CERTIFICATES,
};
