import { gql } from "@apollo/client";

const GET_USER_EXPORT = gql`
  query Exports($exportType: String!) {
    exports(exportType: $exportType) {
      id
      createdAt
      exportType
      updatedAt
      reportUrl
      status
    }
  }
`;

const GET_USER_LAST_EXPORT = gql`
  query Export($exportType: String!) {
    export(exportType: $exportType) {
      id
      createdAt
      updatedAt
      status
      exportType
      reportUrl
    }
  }
`;

const MY_DOWNLOADS = gql`
  query myDownloads($filesPaginatedInput: FilesPaginatedInput!) {
    myDownloads(filesPaginatedInput: $filesPaginatedInput) {
      files {
        id
        createdAt
        errorMessage
        exportType
        status
        reportUrl
      }
      total
    }
  }
`;

export default { GET_USER_EXPORT, GET_USER_LAST_EXPORT, MY_DOWNLOADS };
