import { LOGO_ISOTIPO } from "@constants/global";
import ImageLogoFontsDark from "@public/svg/logo/logo-fonts-dark.svg";
import ImageLogoFontsLight from "@public/svg/logo/logo-fonts-light.svg";
import { useTemplateContext } from "context/template/TemplateContext";
import { useTheme } from "next-themes";
import Image from "next/image";
import { useRouter } from "next/router";
import { PRIVATE_ROUTES } from "routes/routes";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import { TemplateType } from "stores/template/useTemplateStore";

interface LogoGroowProps {
  readonly isImagotipo?: boolean;
  readonly render?: boolean;
  readonly redirect?: boolean;
  readonly dark?: boolean;
  readonly marginLeft?: string;
}

export default function LogoGroow(props: LogoGroowProps) {
  const router = useRouter();
  const { theme } = useTheme();
  const { isImagotipo = true, render = true, redirect = true, dark } = props;

  const { template } = useTemplateContext();

  const { isExpanded: isExPandedSidebar } = useDesktopSidebarStore();

  function handleRedirect(path: string) {
    if (!redirect) return;
    router.push(path);
  }

  if (!render) return null;

  const isAurora = template === TemplateType.Aurora;
  const isClassicTemplate = TemplateType.Classic;

  return (
    <div className="flex items-center">
      <picture
        style={{
          display: "grid",
          alignContent: "center",
          width: "auto",
          minWidth: isImagotipo ? "34px" : "40px",
          marginLeft: isImagotipo
            ? isAurora && isExPandedSidebar
              ? "4px"
              : props.marginLeft
                ? props.marginLeft
                : "24px"
            : isClassicTemplate
              ? "24px"
              : "22px",
          transition: "margin-left 0.3s ease-in-out",
        }}
      >
        <Image
          className="cursor-pointer"
          src={LOGO_ISOTIPO}
          width={40}
          height={40}
          onClick={() => handleRedirect(PRIVATE_ROUTES.profile.path)}
          alt="logo"
        />
      </picture>
      {isImagotipo && (
        <picture
          style={{
            position: "relative",
            display: "grid",
            alignContent: "center",
            width: "100%",
            height: "40px",
            minWidth: "130px",
            marginLeft: "8px",
          }}
        >
          <Image
            className="absolute top-2 cursor-pointer"
            src={
              theme === "dark" || dark
                ? ImageLogoFontsDark
                : ImageLogoFontsLight
            }
            width={
              theme === "dark"
                ? ImageLogoFontsDark.width
                : ImageLogoFontsLight.width
            }
            height={
              theme === "dark"
                ? ImageLogoFontsDark.height
                : ImageLogoFontsLight.height
            }
            onClick={() => handleRedirect(PRIVATE_ROUTES.profile.path)}
            alt="logo fonts"
          />
        </picture>
      )}
    </div>
  );
}
