import { create } from "zustand";
import { persist } from "zustand/middleware";

// Enum TemplateType
export enum TemplateType {
  Classic = "Classic",
  Aurora = "Aurora",
}

// Interfaz ITemplate
export interface ITemplate {
  template: TemplateType;
}

export interface IUseTemplateStore {
  template: ITemplate["template"];
  setTemplate: (template: TemplateType) => void;
  toggleTemplate: () => void;
}

// Creación de la tienda Zustand con persistencia
const useTemplateStore = create(
  persist<IUseTemplateStore>(
    (set) => ({
      template: TemplateType.Aurora, // Valor inicial de la plantilla
      // Función para cambiar el tipo de plantilla
      setTemplate: (template: TemplateType) => set({ template }),
      // Función para alternar entre las plantillas Classic y Aurora
      toggleTemplate: () =>
        set((state) => ({
          template:
            state.template === TemplateType.Classic
              ? TemplateType.Aurora
              : TemplateType.Classic,
        })),
    }),
    {
      name: "template", // Nombre para el almacenamiento en localStorage
      getStorage: () => localStorage, // Usar localStorage para la persistencia
    }
  )
);

export default useTemplateStore;
